<template lang="pug">
    //- Страница на которую попадет юзер через куаркод
    base-layout
        template(slot="main")
            count-code
</template>

<script>
//Components
import BaseLayout from '@/layout/base-layout';
import CountCode from '@/components/views/count';

export default {
    name: "count",
    components: {
        'count-code': CountCode,
        'base-layout': BaseLayout,
    },
}
</script>