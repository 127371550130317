<template lang="pug">
    .flex.flex-col.items-center.text-center
        template(v-if="!isLoading")            
            template(v-if="isAuthorized === 1")
                
                .text-sm.uppercase.mb-2 {{ isNotValidQr ? isNotValidQrMessage : successMessage }}

                template(v-if="qrData && qrData.image")
                    .w-150.mb-4
                        img.w-full(:src="`${baseUrl}/${qrData.image && qrData.image.path ? qrData.image.path : ''}`")
                    .text-sm.uppercase Ты поймал qr-код!
                    .mb-4
                        .text-sm.uppercase.mb-2 Продолжай смотреть<br>ru.tv и ловить новые<br>qr-коды.<br>Больше qr-кодов -<br>больше шансов<br>выиграть супер приз.
                    button.w-150.p-2.bg-gray-300.rounded-full.text-sm(@click="onAccount") Личный кабинет
            
            template(v-if="isAuthorized === 0 && isRegistered !== 0")
                
                .text-sm.uppercase.mb-2 Авторизация
                
                .mb-6
                    .text-sm.uppercase.mb-2 Для участия в конкурсе<br>необходима регистрация
                    button.w-150.p-2.bg-gray-300.rounded-full.text-sm(@click="onRegister") Зарегистрироваться
                
                .mb-4
                    .text-sm.uppercase.mb-4 Если ты уже зарегистрирован,<br>укажи свою электронную почту для<br>зачисления qr-кода в<br>твой личный кабинет
                    .flex.flex-col.items-center
                        .text-sm.text-center.mb-2 Электронная почта
                        input.border-1.h-10.mb-2(type="text" v-model="form.email")
                        template(v-if="isRegistered === 1")
                            input.border-1.h-10.mb-2(type="text" v-model="form.password" placeholder="введите пароль")
                        button.w-150.p-2.bg-gray-300.rounded-full.text-sm(@click="onAuth") Авторизоваться
            
            template(v-if="isRegistered === 0")
                .text-sm Пользователь с таким - email не найден.<br><br>Пожалуйста зарегистрируйся, чтобы участвовать в конкурсе
                button.w-150.p-2.bg-gray-300.rounded-full.text-sm Зарегистрироваться
        template(v-else)
            .text-sm Идет загрузка
</template>

<script>
import { AuthorizationNameSpace, AuthorizationActionTypes } from '@/store/authorization/types';
import { QrItemNameSpace, QrItemActionTypes } from '@/store/qr-item/types';

export default {
    name: "count-code",
    data() {
        return {
            qrData: '',
            isLoading: false,
            isAuthorized: null,
            isRegistered: null,
            form: {
                email: '',
                password: '',
            },
            successMessage: 'Успешно',
            isNotValidQr: false,
            isNotValidQrMessage: '',
        }
    },
    computed: {
        baseUrl() {
            return process.env.VUE_APP_BASE_URL
        },
    },
    mounted() {
        this.onPageInit()
    },
    methods: {
        onPageInit() {
            const id = this.getUrlQuery() 
            if (id) {
                this.onLoad(id)
            }
        },
        getUrlQuery() {
            return this.$route.query.qr
        },
        async onLoad(id) {
            //Загрузка qr кода. Если авторизован - считает, если нет отправляет на регистрацию/авторизацию
            try {
                this.isLoading = true

                const {data} = await this.$custom_axios({
                    method: 'GET',
                    url: `qr/qr-item/get-one`,
                    params: {id}
                })
                
                this.isAuthorized = 1    
                this.qrData = data.data
                
                if (data.data) {
                    await this.onCount(id)
                } 
                if (!data.data) {
                    this.isNotValidQr = true
                    this.isNotValidQrMessage = data.message
                }
            } catch (error) {
                if (error.response.status === 401) {
                    this.isAuthorized = 0
                }
            } finally {
                this.isLoading = false
            }
        },
        async onAuth() {
            //Сначала ищет юзера в базе
            if (this.isRegistered !== 1 && this.isRegistered !== 0) {
                this.onFindAndLogin(this.form)
            }
            //Если есть юзер - авторизует
            if (this.isRegistered === 1) {
                try {
                    const { email, password } = this.form
                    await this.$store.dispatch(`${AuthorizationNameSpace}/${AuthorizationActionTypes.Login}`, { email, password })
                    this.isAuthorized = 1
                    await this.onPageInit()
                } catch (error) {
                    console.log('test', error.response.data.error_message)
                }
            }
        },
        async onFindAndLogin({ email }) {
            const {data} = await this.$store.dispatch(`${AuthorizationNameSpace}/${AuthorizationActionTypes.FindAndLogin}`, { params: { email } })
            this.isRegistered = data.data.is_registered
        },
        onRegister() {
            localStorage.setItem('countUrl', this.$route.fullPath)
            this.$router.push('/registration')
        },
        async onCount(id) {
            const {data} = await this.$store.dispatch(`${QrItemNameSpace}/${QrItemActionTypes.CountQrItem}`, { data: { id } })
            if (data.data.error) {
                this.isNotValidQr = true
                this.isNotValidQrMessage = data.data.error.message
            }
        },
        onAccount() {
            this.$router.push('/account')
        }
    }
}
</script>